@import "../../../evostrap/evostrap/src/css/functions";
@import "../../../evostrap/evostrap/src/css/variables";



.appf-header {
    .appf-header-logo {
        width: 140px;
        display: inline-block;
        img {
            width: 100%;
            height: auto;
        }
    }

    .appf-header-title {
        font-family: Georgia,Garamond,serif;
        line-height: normal;
        font-size: 1.875rem;
    }

    .appf-header-separator {
        background: linear-gradient(180deg,#3d7ac1 0,#345e9c);
        box-shadow: 0 4px 5px rgba(0, 0, 0, .2);


    }
}

.appf-languages-selector {
    a {

        .badge {
            width: 1.5rem;
            height: 1.5rem;
            display: inline-flex;
            align-items: center;
            justify-content: center;
            border: 1px solid $primary;
        }

        &:hover, &:focus {
            .badge {
                background-color: $white;
                color: $primary
            }
        }
    }
}
 